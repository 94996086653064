import "@fortawesome/fontawesome-free/css/all.min.css";
import "./fonts.css";
import './main.css';
import { Elm } from './Main.elm';
import registerServiceWorker from './registerServiceWorker';

var app = Elm.Main.init({
  node: document.getElementById('root')
});

/*const observer = new MutationObserver(function(mutations, observer) {
	mutations.map(function(m) {
		if (m.addedNodes.length > 0) {
		  var iframe = m.addedNodes[0];
			var doc = iframe.contentWindow.document;
			var style = doc.createElement("style");
			style.type = "text/css";
			style.appendChild(doc.createTextNode(".timeline-Header { display: none; }"));
			doc.head.appendChild(style);
		  observer.disconnect();
		}
	});
});

var twtWidget = document.querySelector('div#twitter-timeline-wrapper');

if (twtWidget !== null) {
  observer.observe(document.querySelector('div#twitter-timeline-wrapper'), { childList: true, subtree: true });
}*/

registerServiceWorker();
